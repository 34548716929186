.curtain {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    outline: none;
}

.curtain_wrapper {
    width: 100%;
    height: 100%
}

.curtain_panel {
    background-color: white;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 99;
    transition: all 1s ease-out;
}

.curtain_panel_left {
    background: url("../../public/assets/images/curtain-l.jpg");
    left: 0;
    right: 50%;
}

.curtain_panel_right {
    background: url("../../public/assets/images/curtain-r.jpg");
    left: 50%;
    right: 0;
}

.curtain_content {
    /* background-color: rgba(153,10,77,1); */
    position: absolute;
    z-index: 90;
    width: 100%;
    height: 100%;
}

.curtain_trigger_box {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 100;
    opacity: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
    border: none;
}

.reveal_left {
    transform: translateX(-100%);
}

.reveal_right {
    transform: translateX(100%);
}

.hide {
    display: none
}

.forbidden {
    box-shadow:  0px 0px 3px 4px rgba(245,3,3,1);
    transition: all 0.1s linear
}